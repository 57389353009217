<template>
  <div>
    <Hero :size="size" :title="title" />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="content">
              <h2 class="title is-4 has-text-primary">
                Vacíos de gobernanza y responsabilidad social empresarial
              </h2>
              <p>
                Las empresas transnacionales o multinacionales se definen como
                aquellas que operan en distintos Estados. Son importantes
                actores en las relaciones internacionales. Su actividad resulta
                fundamental para ejecutar grandes proyectos de inversión
                (infraestructuras, telecomunicaciones, energía, etc.) y
                contribuir a la promoción del desarrollo económico de los
                Estados. Al desarrollar estos proyectos, en ocasiones, las
                empresas transnacionales pueden causar perjuicios en el entorno
                social y natural en el que operan.
              </p>
              <p>
                Este proyecto de investigación pretende responder a la siguiente
                pregunta:
                <strong
                  >¿pueden las empresas transnacionales ser titulares de
                  obligaciones internacionales en materia de protección de los
                  derechos humanos y del medio ambiente?</strong
                >
                Para ello, investigaremos en la práctica de los Estados.
              </p>
              <figure class="image">
                <b-image
                  :src="
                    require(`../assets/impacto-empresas-derechos-humanos-medioambiente.png`)
                  "
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-small is-primary">
      <div class="hero-body">
        <div class="container">
          <p class="subtitle is-spaced is-1 my-6">
            “El 26 de junio de 2021, un tribunal neerlandés se basó en el
            Acuerdo de París para obligar a Shell a reducir sus emisiones de
            gases de efecto invernadero en un 45% de aquí a 2030.”
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="content">
              <h2 class="title is-4 has-text-primary">
                Estados y empresas multinacionales: responsabilidades en planos
                diferentes
              </h2>
              <p>
                Los Estados son los responsables de cumplir con las obligaciones
                internacionales en materia de derechos humanos y medio ambiente
                en el Derecho internacional público. Este cumplimiento se
                produce generalmente mediante la incorporación de estas
                obligaciones internacionales en sus ordenamientos jurídicos a
                través de normas de Derecho interno que son obligatorias para
                todos los individuos radicados en su territorio (entre otros,
                las empresas transnacionales). No obstante, la evolución
                experimentada por la sociedad internacional en los últimos años
                está modificando sensiblemente este escenario jurídico.
              </p>
              <p>
                Han comenzado a desarrollarse en el plano internacional algunos
                mecanismos que permitirían a los individuos exigir la tutela de
                aquellas obligaciones internacionales en materia de derechos
                humanos y medio ambiente frente a los Estados y las empresas
                transnacionales. Además de la posibilidad de entablar litigios
                internacionales contra los Estados, se están creando foros
                jurisdiccionales que (mediante el recurso al arbitraje)
                permitirán a los colectivos de la sociedad civil afectados por
                la actividad de las empresas transnacionales entablar
                reclamaciones directamente contra ellas.
              </p>
              <p>
                En la práctica interna de los Estados, aunque de manera todavía
                incipiente y como complemento a lo anterior, algunas
                obligaciones internacionales en materia de derechos humanos y
                medio ambiente comienzan a imponerse directamente sobre las
                empresas.
              </p>
              <p>
                Este proyecto pretende, en definitiva,
                <strong
                  >identificar las medidas que desde el Derecho internacional
                  público y el Derecho de la Unión Europea se están adoptando
                  para incrementar la rendición de cuentas de las empresas
                  multinacionales en materia de Derechos humanos y del
                  medioambiente</strong
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-small is-link">
      <div class="hero-body">
        <div class="container">
          <p class="subtitle is-spaced is-1 my-6">
            “El objetivo es fomentar las buenas prácticas y competitividad de
            las empresas españolas en el exterior, desde una óptica de
            responsabilidad social y respeto de los derechos humanos.”
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="content">
              <h2 class="title is-4 has-text-primary">
                Impacto de este proyecto
              </h2>
              <p>
                En esta página encontrará una
                <strong
                  >innovadora base de datos en materia de inversiones</strong
                >.
              </p>
              <p>
                En la misma encontrará
                <strong>sistematizados y en abierto</strong> las principales
                novedades de este escenario regulatorio emergente para nuestras
                empresas. Mantendremos una fluida interacción con la sociedad
                civil mediante canales de contacto (email y redes sociales) así
                como en Jornadas, Congresos y Seminarios que anunciaremos
                oportunamente. Con ello deseamos asegurar
                <strong
                  >el impacto social de la transferencia de los resultados del
                  proyecto</strong
                >, de forma amplia e inclusiva.
              </p>
              <p>
                Por último, este proyecto tendrá un impacto económico al ser de
                utilidad tanto para el sector privado interesado en prepararse
                ante este escenario, como para aquellas administraciones
                públicas que deseen utilizar sus resultados.
              </p>
              <figure class="image">
                <b-image
                  :src="
                    require(`../assets/competicion-litigacion-internacional-mout-court-universidad-de-alcala.jpg`)
                  "
                  alt=""
                />
                <p class="is-caption">
                  Sesión final de la Competición en Litigación Internacional que
                  celebra cada año la Universidad de Alcalá, participante de
                  este proyecto de investigación y transferencias
                  universidad-empresa.
                </p>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Hero from "./Hero.vue";

export default {
  data() {
    return {
      size: "is-medium",
      title: "Objetivos del proyecto",
      subtitle: "",
    };
  },
  components: {
    Hero,
  },
};
</script>

<style scoped>
strong {
  color: #537382;
}

.is-caption {
  font-style: italic;
}
</style>

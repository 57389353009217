<template>
  <main>
    <Hero :size="size" :title="title" :subtitle="subtitle" />
    <Logos />
    <Featured />
    <Team />
  </main>
</template>

<script>
import Hero from "./Hero.vue";
import Featured from "./Featured.vue";
import Logos from "./Logos.vue";
import Team from "./Team.vue";

export default {
  data() {
    return {
      size: "is-large",
      title: "Derechos Humanos y actividad exterior de las empresas españolas",
      subtitle:
        "¿Pueden las empresas transnacionales ser titulares de obligaciones internacionales en materia de protección de los derechos humanos y del medioambiente?",
    };
  },
  components: {
    Hero,
    Featured,
    Logos,
    Team,
  },
};
</script>

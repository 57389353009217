<template>
  <section :class="`hero ${size} is-primary`">
    <div class="hero-body">
      <div id="tsparticles"></div>
      <div class="container has-text-centered has-background-link py-5">
        <p class="title mb-5">
          {{ title }}
        </p>
        <p v-if="subtitle" class="subtitle">
          {{ subtitle }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { tsParticles } from "tsparticles";
export default {
  mounted() {
    this.initParticles();
  },
  props: {
    size: String,
    title: String,
    subtitle: String,
  },
  methods: {
    initParticles() {
      tsParticles.load("tsparticles", {
        fpsLimit: 60,
        particles: {
          links: {
            enable: true,
            distance: 120,
          },
          move: {
            enable: true,
            speed: 1,
            outModes: {
              default: "bounce",
            },
          },
          size: {
            value: 1,
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 200,
              line_linked: {
                opacity: 1,
              },
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.hero-body {
  position: relative;
}

.container {
  opacity: 0.85;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="card">
    <div class="overlay"></div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p>
            <strong class="has-text-primary">Autoría: </strong
            >{{ publication.author }}
          </p>
          <p>
            <strong class="has-text-primary">Título: </strong
            >{{ publication.title }}
          </p>
          <p>
            <strong class="has-text-primary">Revista: </strong
            >{{ publication.journal }}
          </p>
          <p>
            <strong class="has-text-primary">Número: </strong
            >{{ publication.issue }}
          </p>
          <p>
            <strong class="has-text-primary">Páginas: </strong
            >{{ publication.pages }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    publication: { required: true },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  --text-color-hover: #4c5656;
  --box-shadow-color: #537382;
}
.card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}
.card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}
</style>

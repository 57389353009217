<template>
  <section class="section has-background-light" id="team">
    <div class="container has-text-centered py-4">
      <h2 class="title is-3 has-text-primary">
        Quiénes somos
      </h2>
      <figure class="image is-128x128 is-inline-block mb-6">
        <b-image :src="require(`../assets/employees.png`)" alt="" />
      </figure>
      <h3 class="subtitle is-3 mb-6">
        Investigadores
      </h3>
      <div class="columns is-multiline">
        <div
          class="column is-3"
          v-for="(person, index) in researchers"
          :key="index"
        >
          <Card :person="person" />
        </div>
      </div>
      <br />
      <h3 class="subtitle is-3 mb-6">
        Equipo de trabajo
      </h3>
      <div class="columns is-multiline">
        <div class="column is-3" v-for="(person, index) in team" :key="index">
          <Card :person="person" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Card from "./Card.vue";

export default {
  data() {
    return {
      researchers: [
        {
          name: "IP1: Jaume Ferrer Lloret",
          role:
            "Catedrático de Derecho internacional público y relaciones internacionales de la Universidad de Alicante",
        },
        {
          name: "IP2: Francisco Pascual-Vives",
          role:
            "Profesor Titular de Derecho internacional público y relaciones internacionales de la Universidad de Alcalá",
        },
        {
          name: "Elena Crespo Navarro",
          role:
            "Profesora Titular de Derecho internacional público y relaciones internacionales de la Universidad Miguel Hernández de Elche",
        },
        {
          name: "Millán Requena Casanova",
          role:
            "Profesor Contratado Doctor de Derecho internacional público y relaciones internacionales de la Universidad de Alicante",
        },
        {
          name: "Eugenio Pérez Francisco",
          role:
            "Profesor Ayudante Doctor de Derecho internacional público y relaciones internacionales de la Universidad de Alcalá",
        },
        {
          name: "Adela Aura y Larios de Medrano",
          role:
            "Profesora de Derecho internacional público y relaciones internacionales de la Universidad Cardenal Herrera-CEU",
        },
        {
          name: "Jorge Urbaneja Cillán",
          role:
            "Profesor Ayudante Doctor de Derecho internacional público y relaciones internacionales de la Universidad de Alicante",
        },
        {
          name: "Shaily Stefanny Marroquín García",
          role:
            "Profesora Ayudante de Derecho internacional público y relaciones internacionales de la Universidad de Alicante",
        },
      ],
      team: [
        {
          name: "Carolina Soler García",
          role:
            "Profesora Ayudante Doctor de Derecho internacional público y relaciones internacionales de la Universidad de Alicante",
        },
        {
          name: "Alberto Jiménez-Piernas García",
          role:
            "Investigador doctor del Instituto Universitario de Investigación en Estudios Latinoamericanos de la Universidad de Alcalá",
        },
        {
          name: "Laura Aragonés-Molina",
          role:
            "Investigadora doctora del Instituto Universitario de Investigación en Estudios Latinoamericanos de la Universidad de Alcalá",
        },
        {
          name: "Andrés González Serrano",
          role:
            "Investigador doctor del Instituto Universitario de Investigación en Estudios Latinoamericanos de la Universidad de Alcalá",
        },
        {
          name: "Juliana Rodríguez Patarroyo",
          role:
            "Investigadora del Instituto Universitario de Investigación en Estudios Latinoamericanos de la Universidad de Alcalá",
        },
        {
          name: "Alessandra Nasoni",
          role: "Investigadora de la Universidad Miguel Hernández de Elche",
        },
        {
          name: "Rafael Pastor Beneyto",
          role: "Investigador de la Universidad de Alicante",
        },
      ],
    };
  },
  components: {
    Card,
  },
};
</script>

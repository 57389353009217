<template>
  <div>
    <Hero :size="size" :title="title" />
    <section class="section has-background-light">
      <div class="container">
        <div class="columns is-centered is-multiline">
          <div
            class="column is-9"
            v-for="(activity, index) in activities"
            :key="index"
          >
            <a :href="activity.link" target="_blank">
              <Activity :activity="activity" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Hero from "./Hero.vue";
import Activity from "./Activity.vue";

export default {
  data() {
    return {
      size: "is-medium",
      title: "Actividades",
      subtitle: "",
      activities: [],
    };
  },
  async created() {
    this.activities = await this.getActivities();
  },
  components: {
    Hero,
    Activity,
  },
  methods: {
    getActivities: async () => {
      const query = `{
        actividadesCollection {
          items {
            sys {
              id
            }
            name
            date
            organiser
            link
          }
        }
      }`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
        }),
      };
      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) =>
          response.json()
        );
        return response.data.actividadesCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: #537382;
}

.is-caption {
  font-style: italic;
}
</style>

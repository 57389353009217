/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../components/Index.vue";
import Project from "../components/Project.vue";
import Publicaciones from "../components/Publicaciones.vue";
import Actividades from "../components/Actividades.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Index,
    meta: {
      title:
        "Inicio - Derechos Humanos y actividad exterior de las empresas españolas",
      metaTags: [
        {
          name: "description",
          content:
            "¿Pueden las empresas transnacionales ser titulares de obligaciones internacionales en materia de protección de los derechos humanos y del medioambiente? ",
        },
        {
          property: "og:description",
          content:
            "¿Pueden las empresas transnacionales ser titulares de obligaciones internacionales en materia de protección de los derechos humanos y del medioambiente? ",
        },
      ],
    },
  },
  {
    path: "/proyecto",
    component: Project,
    meta: {
      title:
        "Objetivos del proyecto - Derechos Humanos y actividad exterior de las empresas españolas",
    },
  },
  {
    path: "/publicaciones",
    component: Publicaciones,
    meta: {
      title:
        "Publicaciones - Derechos Humanos y actividad exterior de las empresas españolas",
    },
  },
  {
    path: "/actividades",
    component: Actividades,
    meta: {
      title:
        "Actividades - Derechos Humanos y actividad exterior de las empresas españolas",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;

import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "tsparticles";
import router from "./router";

// Custom Bulma theme
import "@/assets/scss/main.scss";

//FontAwsome
import { library } from "@fortawesome/fontawesome-svg-core";
// internal icons
import { faRss } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTwitter, faInstagram, faYoutube, faRss);

Vue.use("tsparticles");
Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template
  ><section class="section has-background-primary">
    <div class="container has-text-centered has-text-white py-4">
      <h2 class="title has-text-white mb-6">Qué hacemos</h2>
      <div class="columns mb-6 is-multiline">
        <div class="column is-6" v-for="(card, index) in cards" :key="index">
          <div class="level">
            <div class="level-item">
              <figure class="image is-128x128">
                <b-image :src="require(`../assets/${card.logo}`)" alt="" />
              </figure>
            </div>
          </div>
          <h5 class="title is-5 has-text-white">{{ card.name }}</h5>
          <p class="block">
            {{ card.text }}
          </p>

          <b-button
            v-if="!card.external"
            tag="router-link"
            :to="`${card.path}${card.hash}`"
            type="is-white is-outlined"
            :disabled="card.disabled"
          >
            {{ card.button }}
          </b-button>
          <a
            class="button is-white is-outlined"
            v-if="card.external"
            tag="router-link"
            :href="card.path"
            :disabled="card.disabled"
          >
            {{ card.button }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          logo: "file.png",
          text:
            "Éste es un proyecto de investigación oficial financiado por el Ministerio de Ciencia e Innovación de España. Su título completo es 'Los derechos humanos y la actividad exterior de las empresas españolas: Retos y respuestas desde el Derecho Internacional' (Ref. PID2019-107311RB-100).",
          name: "El proyecto",
          button: "Objetivos",
          disabled: false,
          path: "/proyecto",
          hash: "#navbar",
          external: false,
        },
        {
          logo: "sustainable.png",
          text:
            "Como parte del compromiso a favor de las transferencias universidad-empresa-sociedad, estamos trabajando en una base de datos sobre documentos relevantes para las empresas transnacionales cuando operan en el extranjero en materia de responsabilidad social empresarial.",
          name: "Base de Datos",
          button: "Base de datos",
          disabled: false,
          path: "https://negocioresponsable.org/centro-de-recursos/",
          hash: "",
          external: true,
        },
        {
          logo: "book.png",
          text:
            "En esta sección recopilamos las publicaciones científicas asociadas a este proyecto, con un acceso que facilita su difusión al público general y especializado.",
          name: "Publicaciones",
          button: "Publicaciones",
          disabled: false,
          path: "/publicaciones",
          hash: "#navbar",
          external: false,
        },
        {
          logo: "promotion.png",
          text:
            "En esta sección anunciamos nuestras actividades, eventos, seminarios y congresos. También encontrará el listado de actividades ya realizadas.",
          name: "Actividades",
          button: "Actividades",
          disabled: false,
          path: "/actividades",
          hash: "#navbar",
          external: false,
        },
      ],
    };
  },
};
</script>

<style>
.button {
  color: #537382;
}
</style>

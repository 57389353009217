<template>
  <section class="section">
    <div class="container has-text-centered py-4">
      <h2 class="subtitle is-3 mb-6">
        En este proyecto de investigación y de transferencias
        universidad-empresa participan las siguientes instituciones
      </h2>

      <div class="columns is-vcentered is-variable is-8">
        <div class="column" v-for="(logo, index) in logos" :key="index">
          <a :href="logo.link" target="_blank">
            <figure class="image">
              <b-image :src="require(`../assets/${logo.image}`)" alt="" />
            </figure>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        { image: "logo-ua.jpg", link: "https://www.ua.es/" },
        { image: "logo-micinn-aei.jpg", link: "http://www.aei.gob.es/" },
        { image: "logo-uah.png", link: "http://dip.uah.es/" },
      ],
    };
  },
};
</script>
